.services-wrapper{
    background: #f7f8fb;      
}
.services-wrapper .services-h1{
    width: 115px;
    height: 27px;
    margin: auto;
    padding-top: 50px;
    font-family: "helvetica";
    font-size: 25px;
    font-weight: bold;
    line-height: 62px;
    font-style: normal;
    text-align: center;
    color: #0f358e;
}
.services-wrapper .services-text{
    width: 312px;
    height: 123px;
    margin: auto;
    margin-top: 50px;
    font-family: "roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    font-style: normal;
    text-align: center;
    color: #595f6f;
}

@media only screen and (min-width: 600px) {
    .services-wrapper .services-h1{
        width: 100%;
        height: 27px;
        margin: auto;
        padding-top: 5px;
        font-family: "helvetica";
        font-size: 55px;
        font-weight: bold;
        line-height: 62px;
        font-style: normal;
        text-align: center;
        color: #0f358e;
    }
    .services-wrapper .services-text{
        width: 741px;
        height: 130px;
        margin: auto;
        margin-top: 50px;
        font-family: "roboto";
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        font-style: normal;
        text-align: center;
        color: #595f6f;
    }
}