.countryDropdown{    
    padding-left: 15px;
    padding-right: 15px;        
    height: 35px;
    margin: auto;
    border-color: #dddfe7;
    border-width: 1px;
    border-style: solid;
    border-radius: 19px;
    font-family: "helvetica";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
    margin-bottom: 25px;
    position: relative;    
}
.countryDropdown-selected-area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.countries-list{
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 100;    
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #dddfe7;
    border-radius: 7px;
}
.countries-list-search{    
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
.countries-list-search input{
    width: calc(100% - 50px);     
    font-size: 18px;  
    border: none;
    outline: none;
}
.countries-list-search i{
    font-size: 24px;    
}
.countries-list li{
    height: 35px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.countries-list li:hover{
    background: #061A41;
    color: #fff;
}

.countries-list::-webkit-scrollbar {
	width: 11px;
}
.countries-list::-webkit-scrollbar-track {
	background: #CFD8DC;
}
.countries-list::-webkit-scrollbar-thumb {
	background-color: #90A4AE;
	border-radius: 6px;
	border: 3px solid #CFD8DC;
}

@media only screen and (min-width: 600px) {  
    .countryDropdown{ 
        height: 80px;    
        font-size: 20px;
        font-weight: 400;
        line-height: 18px;
        font-style: normal;
        text-align: left;
        color: #595f6f;
        margin-bottom: 32px;
    } 
    .countries-list{
        position: absolute;
        top: 85px;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 100;
        max-height: 300px;
        overflow-y: scroll;
        border: 1px solid #dddfe7;
        border-radius: 7px;
    }
}