.aboutUs{
    width: 100%;    
    background: #f7f8fb;
    padding-top: calc(37px + 66px);
    padding-bottom: 50px;    
}
.aboutUsList{
    margin-left: 5px;
    font-size: 14px;
}
.aboutUsList li{
    margin-bottom: 8px;
}
.aboutUs strong{
    font-weight: bold;
}
.aboutUs .aboutUs-inner{
    width: 352px;
    margin: auto;
    padding-top: 43px;
    padding-bottom: 43px;
    border-radius: 5px;
    filter: drop-shadow(30px 30px 30px rgba(0,0,0,0.3 ));
    background: #ffffff;
    -webkit-filter: drop-shadow(30px 30px 30px rgba(0,0,0,0.3 ));
}
.aboutUs .aboutUs-inner p{
    width: 80%;
    margin: auto;
    font-family: "helvetica";
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.aboutUs-heading{
    display: none;
}
.aboutUs-brief-txt{
    display: none;
}
.linkText {
    color: #709be7;
    font-weight: 600;
    text-align: center;
}
@media only screen and (min-width: 600px) {
    .aboutUs{
        width: 100%;    
        background: #f7f8fb;
        padding-top: calc(37px + 66px);    
        padding-bottom: 100px;           
    }
    .aboutUs-heading{
        display: block;
        width: 630px;
        height: 54px;
        margin: auto;
        margin-top: 34px;
        font-family: "helvetica";
        font-size: 53px;
        font-weight: bold;
        line-height: 62px;
        font-style: normal;
        text-align: center;
        color: #0f358e;
    }
    .aboutUs-brief-txt{
        display: block;                        
        margin-top: 25px;
        margin-bottom: 20px;
        font-family: "helvetica";
        font-size: 25px;
        font-weight: 300;
        line-height: 20px;
        font-style: normal;
        text-align: center;
        color: #595f6f;
    }
    .aboutUs .aboutUs-inner{
        padding-top: 43px;
        padding-bottom: 43px;
        border-radius: 5px;
        filter: drop-shadow(30px 30px 30px rgba(0,0,0,0.3 ));
        background: #ffffff;
        width: 80%;
        max-width: 1256px;
        margin: auto;
        -webkit-filter: drop-shadow(30px 30px 30px rgba(0,0,0,0.3 ));
}
    .aboutUs .aboutUs-inner p{
        font-family: "helvetica";
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        font-style: normal;
        text-align: justify;
        color: #707070;
        text-justify: auto;        
    }
}