.selfieDPIText {
  height: 15px;
  font-family: 'helvetica';
  font-size: 17px;
  font-weight: 200;
  line-height: 20px;
  font-style: normal;
  text-align: left;
  color: #56585c;
  margin-bottom: 15px;
}
.selfieDPI {
  width: 25%;
  min-width: 180px;
  border-radius: 20px;
  margin: 0 auto 20px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.kycForm {
  width: 348px;
  margin: auto;
  margin-top: 35px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 28px;
  filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
  background: #ffffff;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  -o-border-radius: 28px;
  -webkit-filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
}
.kycForm .inner {
  width: 80%;
  margin: auto;
}
.kycForm-txt1 {
  height: 13px;
  font-family: 'helvetica';
  font-size: 12px;
  font-weight: 300;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #595f6f;
  margin-top: 6px;
  margin-left: 5px;
}
.kycForm-txt2 {
  height: 8px;
  font-family: 'helvetica';
  font-size: 8px;
  font-weight: 300;
  line-height: 10px;
  font-style: normal;
  text-align: left;
  color: #595f6f;
  margin-top: 21px;
  margin-bottom: 10px;
}
.stepperkycForm-enter-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.stepperkycForm-enter-btn button {
  width: 128.6px;
  height: 35px;
  filter: drop-shadow(2.5px 2.5px 3.75px rgba(0, 0, 0, 0.4));
  background: #1a47b0;
  font-family: 'helvetica';
  font-size: 12px;
  font-weight: 400;
  line-height: 8px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
}
.birthDate {
  outline: none;
  border: none;
  width: calc(100% - 17px);
  padding-left: 15px;
  height: 35px;
  margin: auto;
  border-color: #dddfe7;
  border-width: 1px;
  border-style: solid;
  border-radius: 19px;
  font-family: 'helvetica';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #595f6f;
  margin-bottom: 14px;
}
.red-border {
  border: 1px solid red !important;
}
.kyc-label {
  font-size: 14px;
  color: #595f6f;
  margin-bottom: 5px;
  margin-left: 5px;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}
@media only screen and (min-width: 600px) {
  .kycForm {
    width: 80%;
    max-width: 1256px;
    margin: auto;
    margin-top: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .kycForm-txt1 {
    width: 876px;
    height: 29px;
    font-family: 'helvetica';
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    font-style: normal;
    text-align: center;
    color: #595f6f;
    margin-top: 0px;
  }
  .kycForm-txt2 {
    height: 25px;
    font-family: 'helvetica';
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
    margin-bottom: 22px;
  }
  .stepperkycForm-enter-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .stepperkycForm-enter-btn button {
    width: 180px;
    height: 80px;
    filter: drop-shadow(2.5px 2.5px 3.75px rgba(0, 0, 0, 0.4));
    background: #1a47b0;
    font-family: 'helvetica';
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .birthDate {
    padding-top: 25px;
    padding-bottom: 20px;
    height: 80px;
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
    margin-bottom: 32px;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none !important;
  }
}
