.popupLi{
    margin-bottom: 9px;
}
.popupP{
    margin-top: 15px;
    font-size: 20px;
}
.homeBanner{
    width: 100%;
    height: 439px;
    background: #123075;
    background: linear-gradient(180deg, 
    #123075 0.00%, 
    #092056 100.00%);   
    overflow: hidden; 
    position: relative;    
}
.homeBanner .homeBanner-text-h{
    position: absolute;
    top: 190px;
    left: 40px;    
    font-family: "helvetica";
    font-size: 15px;
    font-weight: 400;
    line-height: 9px;
    font-style: normal;
    text-align: left;    
    color: #709be7;
    z-index: 1;
}
.homeBanner .homeBanner-text{
    position: absolute;
    top: 216px;
    left: 40px;
    width: 274px;
    height: 43px;
    font-family: "helvetica";
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    z-index: 10;
}
.homeBanner .comprar-btn{
    position: absolute;
    top: 286px;
    left: 40px;    
    font-family: "helvetica";
    font-size: 12px;
    font-weight: 400;
    line-height: 8px;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    width: 99px;
    height: 32px;
    border-radius: 27px;
    filter: drop-shadow(20px 20px 30px rgba(0,0,0,0.4 ));
    background: #fa244a;
    text-align: center;
    border: none;
    outline: none;
}
.homeBanner .rightimg{
    object-fit:  cover;
    position: absolute;    
    top: 0px;
    right: -250px;
    width: 543.7px;
    height: 362.5px;
    border-radius: 800px;
    transform:rotate(-30deg);
    -webkit-transform:rotate(-30deg);
    -moz-transform:rotate(-30deg);
    -ms-transform:rotate(-30deg);
    -o-transform:rotate(-30deg);
    -webkit-border-radius: 800px;
    -moz-border-radius: 800px;
    -ms-border-radius: 800px;
    -o-border-radius: 800px;
    opacity: 0.49;
    border-radius: 800px;
    filter: drop-shadow(-40px 40px 60px rgba(0,0,0,0.3 ));
    background: #e0e0e0;
    background: linear-gradient(-100deg, 
    #e0e0e0 0.00%, 
    #292929 100.00%);
}

@media only screen and (min-width: 600px) {
    .homeBanner{
        width: 100%;
        height: 120vh;
        background: #123075;
        background: linear-gradient(180deg, 
        #123075 0.00%, 
        #092056 100.00%);
        overflow: hidden; 
        position: relative;   
        display: flex;
        align-items: center; 
    }      
    .homeBanner .rightimg{
        object-fit:  cover;
        position: absolute;    
        top: -40%;
        right: -40%;
        width: 100%;
        height: 100%;
        border-radius: 800px;
        transform:rotate(-30deg);
        -webkit-transform:rotate(-30deg);
        -moz-transform:rotate(-30deg);
        -ms-transform:rotate(-30deg);
        -o-transform:rotate(-30deg);
        -webkit-border-radius: 800px;
        -moz-border-radius: 800px;
        -ms-border-radius: 800px;
        -o-border-radius: 800px;
        opacity: 0.49;
        border-radius: 800px;
        filter: drop-shadow(-40px 40px 60px rgba(0,0,0,0.3 ));
        background: #e0e0e0;
        background: linear-gradient(-100deg, 
        #e0e0e0 0.00%, 
        #292929 100.00%);
    }
    .homeBanner .left-text{
        width: 90%;
        max-width: 1256px;
        margin: auto;               
    } 
    .homeBanner .homeBanner-text-h{
        position: relative;
        top: 0;
        font-family: "helvetica";
        font-size: 28px;
        font-weight: 400;
        line-height: 33px;
        font-style: normal;
        text-align: left;    
        color: #709be7;
        z-index: 1;
    }
    .homeBanner .homeBanner-text{
        position: relative;
        top: 20px;
        width: 80%;
        height: auto;
        font-family: "helvetica";
        font-size: 34px;
        font-weight: bold;
        line-height: 54px;
        font-style: normal;
        text-align: left;
        color: #ffffff;        
    }
    .homeBanner .comprar-btn img{
        height: 24px;   
        margin-left: 5px;     
    }
    .homeBanner .comprar-btn{
        position: relative;
        top: 50px;
        font-family: "helvetica";
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
        width: 230px;
        height: 58px;
        border-radius: 27px;
        filter: drop-shadow(20px 20px 30px rgba(0,0,0,0.4 ));
        background: #fa244a;
        text-align: center;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;        
    }
}
@media only screen and (min-width: 670px) {
    .homeBanner .homeBanner-text{
        position: relative;
        top: 20px;
        width: 80%;
        height: auto;
        font-family: "helvetica";
        font-size: 44px;
        font-weight: bold;
        line-height: 54px;
        font-style: normal;
        text-align: left;
        color: #ffffff;        
    }
    .homeBanner .comprar-btn{
        position: relative;
        top: 50px;
        font-family: "helvetica";
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
        width: 230px;
        height: 68px;
        border-radius: 27px;
        filter: drop-shadow(20px 20px 30px rgba(0,0,0,0.4 ));
        background: #fa244a;
        text-align: center;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;        
    }
}

@media only screen and (min-width: 900px) {
    .homeBanner .homeBanner-text{
        position: relative;
        top: 30px;
        width: 80%;
        height: auto;
        font-family: "helvetica";
        font-size: 54px;
        font-weight: bold;
        line-height: 68px;
        font-style: normal;
        text-align: left;
        color: #ffffff;        
    }
}
@media only screen and (min-width: 1060px) {
    .homeBanner .homeBanner-text{
        position: relative;
        top: 30px;
        width: 80%;
        height: auto;
        font-family: "helvetica";
        font-size: 74px;
        font-weight: bold;
        line-height: 88px;
        font-style: normal;
        text-align: left;
        color: #ffffff;        
    }
}