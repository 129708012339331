.inner-w .kycText {
    width: 90%;
    max-width: 1256px;
    margin: auto; 
}
.kycText1{    
    margin: auto;
    width: 209px;
    height: 27px;
    font-family: "helvetica";
    font-size: 23px;
    font-weight: bold;
    line-height: 62px;
    font-style: normal;
    text-align: center;
    color: #0f358e;
}
.kycText2{
    text-align: justify;
    width: 317px;
    height: 61px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 140px;
    font-family: "helvetica";
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    font-style: normal;
    color: #595f6f;
}
@media only screen and (min-width: 600px) {
    .kycText1{    
        width: 470px;
        height: 54px;
        margin: auto;
        font-family: "helvetica";
        font-size: 55px;
        font-weight: bold;
        line-height: 62px;
        font-style: normal;
        text-align: left;
        color: #0f358e;
    }
    .kycText2{
        text-align: justify;
        width: 876px;
        height: 68px;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 140px;
        font-family: "helvetica";
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        font-style: normal;
        text-align: center;
        color: #595f6f;
    }
}