.aboutUs {
  position: relative;
  width: 100%;
  background: #f7f8fb;
}
.aboutUs .aboutUs-mobile {
  display: block;
}
.aboutUs .aboutUs-mobile .aboutUs-text1 {
  position: relative;
  width: 178px;
  margin: auto;
  margin-top: -60px;
  margin-bottom: 30px;
  height: 25px;
  font-family: 'helvetica';
  font-size: 23px;
  font-weight: bold;
  line-height: 62px;
  font-style: normal;
  text-align: center;
  color: #0f358e;
}
.aboutUs .aboutUs-mobile .aboutUs-text2 {
  width: 310px;
  margin: auto;
  font-family: 'helvetica';
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
  text-align: center;
  color: #595f6f;
}
.aboutUs .aboutUs-mobile .aboutusImgMobile-w {
  text-align: center;
}
.aboutUs .aboutUs-mobile .aboutusImgMobile {
  width: 90vw;
  max-width: 900px;
  margin: auto;
  border-radius: 5px;
  filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.5));
  -webkit-filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.5));
  object-fit: cover;
}
.aboutUs .aboutUs-pc {
  display: none;
}
@media only screen and (min-width: 600px) {
  .aboutUs {
    position: relative;
    width: 100%;
    background: #f7f8fb;
  }
  .aboutUs .aboutUs-mobile {
    display: none;
  }
  .aboutUs .aboutUs-pc {
    display: block;
    width: 90%;
    max-width: 1256px;
    margin: auto;
    height: 100%;
    align-items: center;
    text-align: center;
    padding-top: 50px;
  }
  .aboutUs .aboutUs-pc .aboutUs-text1 {
    width: 481px;
    height: 54px;
    font-family: 'helvetica';
    font-size: 40px;
    font-weight: bold;
    line-height: 62px;
    font-style: normal;
    text-align: left;
    color: #0f358e;
    margin-top: 50px;
  }
  .aboutUs .aboutUs-pc .aboutUs-text2 {
    font-family: 'helvetica';
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
    margin-top: 22px;
  }
  .aboutUs .aboutUs-pc .aboutusImgPc {
    width: 611px;
    height: 611px;
    border-radius: 5px;
    filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.5));
    -webkit-filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.5));
  }
}

@media only screen and (min-width: 1080px) {
  .aboutUs {
    position: relative;
    width: 100%;
    background: #f7f8fb;
  }
  .aboutUs .aboutUs-mobile {
    display: none;
  }
  .aboutUs .aboutUs-pc {
    display: flex;
    width: 90%;
    max-width: 1256px;
    margin: auto;
    height: 100%;
    align-items: center;
    padding: 0;
  }
  .aboutUs .aboutUs-pc .aboutUs-text1 {
    width: 481px;
    height: 54px;
    font-family: 'helvetica';
    font-size: 40px;
    font-weight: bold;
    line-height: 62px;
    font-style: normal;
    text-align: left;
    color: #0f358e;
    margin: 0;
  }
  .aboutUs .aboutUs-pc .aboutUs-text2 {
    font-family: 'helvetica';
    font-size: 25px;
    font-weight: 300;
    line-height: 40px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
    margin-top: 22px;
  }
  .aboutUs .aboutUs-pc .aboutusImgPc {
    width: 611px;
    height: 611px;
    border-radius: 5px;
    filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.5));
  }
}
