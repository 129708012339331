.haveQuestionForm{  
    position: relative;  
    width: 100%;
    height: 664px;
    background: #123075;
    background: linear-gradient(180deg, 
    #123075 0.00%, 
    #092056 100.00%);
}
.haveQuestionForm .haveQuestionForm-mobile .haveQuestionForm-text1{   
    position: absolute; 
    top: 47px;
    width: 100%;
    height: 18px;
    margin: auto;    
    font-family: "helvetica";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    font-style: normal;
    text-align: center;
    color: #dddfe7;
}
.haveQuestionForm .haveQuestionForm-mobile .haveQuestionForm-text2{   
    position: absolute;
    top: 82px;
    left: calc(50% - 114.3px);
    width: 228.6px;
    height: 105.6px;
    font-family: "helvetica";
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
}
.haveQuestionForm .haveQuestionForm-mobile .socialIcons{   
    position: absolute;
    top: 550px;
    left: calc(50% - 100px);
    width: 200px;
    display: flex;
    justify-content: space-between;
}
.haveQuestionForm .haveQuestionForm-mobile .socialIcons .socialIcon{   
    width: 28.5px;
    height: 28.5px;
    background: #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.haveQuestionForm .haveQuestionForm-pc{
    display: none;
}
@media only screen and (min-width: 600px) {
    .haveQuestionForm{  
        position: relative;  
        width: 100%;
        height: 1027px;
        background: #123075;
        background: linear-gradient(180deg, 
        #123075 0.00%, 
        #092056 100.00%);
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .haveQuestionForm .haveQuestionForm-mobile{
        display: none;
    }
    .haveQuestionForm .haveQuestionForm-pc{
        height: 100%;
        display: block;        
        width: 80%;
        max-width: 1256px;
        margin: auto;        
    }
    .haveQuestionForm .haveQuestionForm-pc .haveQuestionForm-text1{        
        height: 27px;
        font-family: "helvetica";
        font-size: 27px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
        text-align: center;
        color: #dddfe7;        
    }
    .haveQuestionForm .haveQuestionForm-pc .haveQuestionForm-text2{                        
        width: 80%;
        height: 127px;
        font-family: "helvetica";
        font-size: 55px;
        font-weight: bold;
        line-height: 62px;
        font-style: normal;
        text-align: center;
        color: #ffffff;
        margin: auto;
        margin-top: 31px;
        margin-bottom: 10px;
    }
    .haveQuestionForm .haveQuestionForm-pc .socialIcons{   
        position: relative;
        top: 0;
        left: 0;
        width: 200px;
        margin: auto; 
        margin-bottom: 50px;       
        display: flex;
        justify-content: space-between;
    }
    .haveQuestionForm .haveQuestionForm-pc .socialIcons .socialIcon{   
        width: 28.5px;
        height: 28.5px;
        background: #ffffff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (min-width: 1080px) {
    .haveQuestionForm{  
        position: relative;  
        width: 100%;
        height: 1027px;
        background: #123075;
        background: linear-gradient(180deg, 
        #123075 0.00%, 
        #092056 100.00%);
        padding: 0;
    }
    .haveQuestionForm .haveQuestionForm-mobile{
        display: none;
    }
    .haveQuestionForm .haveQuestionForm-pc{
        height: 100%;
        display: block;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        max-width: 1256px;
        margin: auto;        
    }
    .haveQuestionForm .haveQuestionForm-pc .haveQuestionForm-text1{        
        height: 27px;
        font-family: "helvetica";
        font-size: 27px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
        text-align: left;
        color: #dddfe7;
    }
    .haveQuestionForm .haveQuestionForm-pc .haveQuestionForm-text2{                        
        width: 484px;
        height: 127px;
        font-family: "helvetica";
        font-size: 55px;
        font-weight: bold;
        line-height: 62px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
        margin-top: 31px;
        margin-bottom: 10px;
    }
    .haveQuestionForm .haveQuestionForm-pc .socialIcons{   
        position: relative;
        top: 0;
        left: 0;
        width: 200px;
        display: flex;
        justify-content: space-between;
        margin: 0;
    }
    .haveQuestionForm .haveQuestionForm-pc .socialIcons .socialIcon{   
        width: 28.5px;
        height: 28.5px;
        background: #ffffff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }  
}