.cards-w{
    background: #f7f8fb; 
    padding-top: 87px;
    padding-bottom: 87px;
    width: 100%;
    overflow-x: hidden;
}

@media only screen and (min-width: 600px) {
    /* .cards-w{
        background: #f7f8fb; 
        padding-top: 87px;
        padding-bottom: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
    }    */
}
@media only screen and (min-width: 1080px) {
    .cards-w{
        background: #f7f8fb; 
        padding-top: 87px;
        padding-bottom: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
    }  
}