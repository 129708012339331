.criptoTable-wrapper{
    width: 100%;    
    background: #f7f8fb;
}
.criptoTable-wrapper .inner-wrapper{
    width: 95%;
    max-width: 1256px;
    margin: auto;
}
.criptoTable-wrapper .criptoTable-text1{    
    height: 13px;
    font-family: "helvetica";
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    font-style: normal;
    text-align: left;
    color: #3e3e3e;
    margin-top: 20px;
    margin-left: 31px;    
}
.criptoTable-wrapper .criptoTable-text2{    
    /* width: 333px; */
    height: 25px;
    font-family: "helvetica";
    font-size: 11px;
    font-weight: 300;
    line-height: 14px;
    font-style: normal;
    text-align: left;
    color: #3e3e3e;
    margin-top: 24px;
    margin-left: 31px; 
}
.criptoTable-wrapper .criptoTable-mobile{    
    background: #fff;
    margin-top: 53px;    
    margin-left: 31px;     
    width: calc(100% - 61px);
}
.criptoTable-wrapper .criptoTable-mobile thead{            
    height: 50px;
    line-height: 50px;        
}
.criptoTable-wrapper .criptoTable-mobile th{    
    font-size: 13px;
    font-weight: bold;
    text-align: left;
}
.criptoTable-wrapper .criptoTable-mobile tbody tr{
    height: 50px;
    line-height: 50px;
}
.criptoTable-wrapper .criptoTable-mobile .cripto-table-mobile-name{    
    width: 130px;
    position: relative;
}
.criptoTable-wrapper .criptoTable-mobile .cripto-table-mobile-name img{
    position: absolute;
    top: 8px;
    left: 5px;
}
.criptoTable-wrapper .criptoTable-mobile .cripto-table-mobile-name .name-span{
    position: absolute;
    top: -10px;
    left: 45px;
}
.criptoTable-wrapper .criptoTable-mobile .cripto-table-mobile-name .symbol-span{
    position: absolute;
    bottom: -12px;
    left: 45px;
    font-size: 14px;
    color: grey;
    text-transform: uppercase;
}
.criptoTable-wrapper .criptoTable-pc{    
    display: none;
}

@media only screen and (min-width: 600px) {
    .criptoTable-wrapper .criptoTable-mobile{
        display: none;        
    }
    .criptoTable-wrapper .criptoTable-text1{    
        width: 100%;        
        height: 13px;
        font-family: "helvetica";
        font-size: 25px;
        font-weight: bold;
        line-height: 57px;
        font-style: normal;
        text-align: left;
        color: #3e3e3e;
        margin-top: 20px;
        margin-left: 0;          
    }
    .criptoTable-wrapper .criptoTable-text2{    
        width: 100%;
        height: auto;
        font-family: "helvetica";
        font-size: 20px;
        font-weight: 300;
        line-height: 57px;
        font-style: normal;
        text-align: left;
        color: #3e3e3e;
        margin-top: 24px;
        margin-left: 0px; 
    }
    .criptoTable-wrapper .criptoTable-pc{  
        display: table;  
        background: #fff;
        margin-top: 53px;             
        width: 100%;        
        overflow-x: scroll;
        border-radius: 20px;
    }
    .criptoTable-wrapper .criptoTable-pc th{    
        font-size: 13px;
        font-weight: bold;
        text-align: left;                
        min-width: 100px;
    }
    .criptoTable-wrapper .criptoTable-pc thead{            
        height: 50px;
        line-height: 50px;        
    }
    .criptoTable-wrapper .criptoTable-pc tbody tr{ 
        height: 50px;
        line-height: 50px;
    }    
    .criptoTable-wrapper .criptoTable-pc tbody td img{ 
        position: relative;
        top: 8px;
        margin-left: 5px;
    }
}