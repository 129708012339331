.whiteCard-wrapper{
    width: 236px;
    height: 312px;
    margin: auto;
    border-radius: 43px;
    filter: drop-shadow(40px 40px 40px rgba(0,0,0,0.1 ));
    background: #ffffff;
    position: relative; 
    margin-bottom: 100px;
}
.whiteCard-wrapper .whiteCard-heading{
    position: absolute;
    top: 30px;
    left: 20px;
    width: 124px;
    height: 34px;
    font-family: "helvetica";
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;    
    font-style: normal;
    text-align: left;
    color: #0f358e;
}
.whiteCard-wrapper .whiteCard-text{
    position: absolute;
    bottom: 30px;
    left: 20px;
    width: 201px;    
    font-family: "roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #595f6f;       
}
.whiteCard-wrapper .whiteCard-image{
    position: absolute;
    top: -54px;
    right: -54px;
}
.whiteCard-wrapper:hover{
    background: #112E72;
}
.whiteCard-wrapper:hover .whiteCard-heading{
    color: white;
}
.whiteCard-wrapper:hover .whiteCard-text{
    color: white;
}
@media only screen and (min-width: 600px) {
    .whiteCard-wrapper{
        width: 351px;
        height: 462px;
        margin: 0;
        border-radius: 43px;
        filter: drop-shadow(40px 40px 40px rgba(0,0,0,0.1 ));
        background: #ffffff;
        position: relative;
        -webkit-filter: drop-shadow(40px 40px 40px rgba(0,0,0,0.1 ));
        -webkit-border-radius: 43px;
        -moz-border-radius: 43px;
        -ms-border-radius: 43px;
        -o-border-radius: 43px;
        margin: auto;
        margin-bottom: 80px;
    }   
    .whiteCard-wrapper .whiteCard-heading{
        position: absolute;
        top: 40px;
        left: 20px;
        width: 184px;
        height: 50px;
        font-family: "helvetica";
        font-size: 25px;
        font-weight: bold;
        line-height: 25px;
        font-style: normal;
        text-align: left;
        color: #0f358e;
    }
    .whiteCard-wrapper .whiteCard-text{
        position: absolute;
        bottom: 37px;
        left: 27px;
        width: 298px;
        height: 194px;
        font-family: "roboto";
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        font-style: normal;
        text-align: left;
        color: #595f6f;    
    }
    .whiteCard-wrapper .whiteCard-image{
        position: absolute;
        top: -34px;
        right: -44px;
    }
    .pc-spacer40{
        width: 40px;
    }
}
@media only screen and (min-width: 1080px) {
    .whiteCard-wrapper{
        margin: 0;
    }
}