.page-header{    
    width: 100%;    
    height: 66px;
    background: #051741;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100!important;
}
.page-header .movile-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1256px;
    margin: auto; 
}
.page-header .movile-header .bars{    
    cursor: pointer;
}
.page-header .movile-header .logo{    
    width: 76px;
    height: 25px;
    font-family: "helvetica";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    font-style: normal;
    text-align: center;
    color: #ffffff;    
    cursor: pointer;
}
.page-header .movile-header .logo img{    
    width: 100%;
    height: 100%;
}
.right-header-nav{
    background: #123075;
    background: linear-gradient(180deg, 
    #123075 0.00%, 
    #092056 100.00%);
    height: 100vh;
}
.drar-logo{
    width: 76px;
    height: 25px;
    font-family: "helvetica";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    font-style: normal;
    text-align: center;
    color: #ffffff;    
    cursor: pointer;
}
.drar-logo img{
    width: 100%;
    height: 100%;
}
.drar-logo span{
    font-weight: bold;
}
.header-drar-links{
    padding: 10px;    
}
.header-drar-links a{         
    color: #fff;
}
.header-drar-links li{         
    height: 50px;    
    line-height: 50px;
    text-align: center;
}
.page-header .movile-header .logo span{
    font-weight: bold;
}
.page-header-links{
    display: none;
}
.page-header .page-header-socialIcons{
    display: none;
}

.header-drawr-socialIcons{   
    width: 200px;
    display: flex;
    margin: auto;
    margin-top: 24px;
    justify-content: space-between;
}
.header-drawr-socialIcons .header-drawr-socialIcon{   
    width: 28.5px;
    height: 28.5px;
    background: #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.header-drawr-socialIcons{
    cursor: pointer;
}

@media only screen and (min-width: 999px) {
    .page-header-humbarger{
        display: none;
    }
    .page-header .movile-header .logo{    
        width: 145px;
        height: 46px;
        font-family: "helvetica";
        font-size: 21px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
        text-align: center;
        color: #ffffff;
    }
    .page-header-links{
        display: flex;
        align-items: center;
        height: 66px;
    }
    .page-header-links li{                        
        line-height: 66px;        
        border: none;
        margin-right: 39px;
        font-family: "helvetica";
        font-size: 21px;
        font-weight: 400;        
        font-style: normal;        
        color: #709be7;
    }
    .page-header-links .active{                        
        line-height: 66px;        
        border-bottom: 4px solid #fff;
        margin-right: 39px;
        font-family: "helvetica";
        font-size: 21px;
        font-weight: 400;        
        font-style: normal;        
        color: #ffffff;
    }
    .page-header .page-header-socialIcons{   
        width: 200px;
        display: flex;
        justify-content: space-between;
    }
    .page-header .page-header-socialIcons .page-header-socialIcon{   
        width: 28.5px;
        height: 28.5px;
        background: #ffffff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .page-header .page-header-socialIcons{
        cursor: pointer;
    }
}   