.kycInput{    
    text-align: center;
    margin-bottom: 14px;
}
.kycInput input{      
    outline: none;
    border: none;
    width: calc(100% - 17px);    
    padding-left: 15px;
    height: 35px;
    margin: auto;
    border-color: #dddfe7;
    border-width: 1px;
    border-style: solid;
    border-radius: 19px;
    font-family: "helvetica";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
}
@media only screen and (min-width: 600px) {
    .kycInput{    
        text-align: center;
        margin-bottom: 32px;
    }
    .kycInput input{ 
        height: 80px;    
        font-size: 20px;
        font-weight: 400;
        line-height: 18px;
        font-style: normal;
        text-align: left;
        color: #595f6f;
    }
}