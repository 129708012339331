.kyc {
  background: #f7f8fb;
  padding-top: calc(34px + 66px);
  padding-bottom: 50px;
}
.birthDate {
  outline: none;
  border: none;
  width: calc(100% - 17px);
  padding-left: 15px;
  height: 35px;
  margin: auto;
  border-color: #dddfe7;
  border-width: 1px;
  border-style: solid;
  border-radius: 19px;
  font-family: 'helvetica';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #595f6f;
  margin-bottom: 14px;
}
@media only screen and (min-width: 600px) {
  .kyc {
    padding-top: calc(189px);
    padding-bottom: 100px;
  }
}
