.footer{
    position: relative;
    width: 100%;
    height: 716px;
    background: #123075;
    background: linear-gradient(180deg, 
    #123075 0.00%, 
    #092056 100.00%);
}
.footer .footer-logo{
    position: absolute;
    top: 74px;
    left: calc(50% - 72.5px);
    width: 145px;
    height: 46px;
    font-family: "helvetica";
    font-size: 21px;
    font-weight: 400;
    line-height: 26px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
}
.footer .footer-logo span{
    font-weight: bold;
}
.footer .footer-text1{
    position: absolute;
    top: 172px;
    left: calc(50% - 136.5px);
    width: 273px;
    height: 42px;
    font-family: "helvetica";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
}
.footer .footer-text2{
    position: absolute;
    top: 227px;
    left: calc(50% - 136.5px);
    width: 273px;
    height: 56px;
    font-family: "helvetica";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-style: normal;
    text-align: center;
    color: #709be7;
}
.footer .footer-nav-h, .footer-contacts-h{
    position: absolute;    
    left: calc(50% - 76.5px);
    width: 153px;
    height: 28px;
    font-family: "helvetica";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
}
.footer .footer-nav-h{
    top: 317px;
}
.footer .footer-contacts-h{
    top: 515px;
}
.footer .footer-links{
    position: absolute;
    top: 359px;
    left: calc(50% - 76.5px);
    width: 153px;
    height: 126px;
    font-family: "helvetica";
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    font-style: normal;
    text-align: center;
    color: #709be7;
}
.footer .footer-links li{
    cursor: pointer;
}
.footer .footer-links li a{
    color: #709be7;
}
.footer .footer-contact-info{
    position: absolute;
    top: 557px;
    left: calc(50% - 67.5px);
    width: 135px;
    height: 59px;
    font-family: "roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    font-style: normal;
    text-align: center;
    color: #709be7;
}
.footer .footer-socialIcons{   
    position: absolute;
    top: 640px;
    left: calc(50% - 100px);
    width: 200px;
    display: flex;
    justify-content: space-between;
}
.spacer154{
    display: none;
}
.spacer113{
    display: none;
}
.footer-logo-img{
    width: 100%;
}
.footer .footer-socialIcons .footer-socialIcon{   
    width: 28.5px;
    height: 28.5px;
    background: #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-linkText{
    color: #709be7;
    font-weight: 600;
}
@media only screen and (min-width: 850px) {
    .footer{
        position: relative;
        width: 100%;
        height: 584px;
        background: #123075;
        background: linear-gradient(180deg, 
        #123075 0.00%, 
        #092056 100.00%);        
    }
    .footer-inner{
        display: flex;
        align-items: center;        
        height: 100%;
        width: 90%;
        max-width: 1256px;
        margin: auto; 
    }
    .footer .footer-logo{
        position: relative;
        top: 0;
        left: 0;
        width: 145px;
        height: 46px;
        font-family: "helvetica";
        font-size: 21px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
    }
    .footer .footer-logo span{
        font-weight: bold;
    }
    .footer .footer-text1{
        position: relative;
        top: 0;
        left: 0;
        width: 481px;
        height: 28px;
        font-family: "helvetica";
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
        margin-top: 20px;
    }
    .footer .footer-text2{
        position: relative;
        top: 0;
        left: 0;
        width: 481px;
        height: 48px;
        font-family: "helvetica";
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        font-style: normal;
        text-align: left;
        color: #709be7;
        margin-bottom: 43px;
    }
    .footer .footer-nav-h, .footer-contacts-h{
        position: relative;    
        left: 0;
        width: 153px;
        height: 28px;
        font-family: "helvetica";
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
    }
    .footer .footer-nav-h{
        top: 0;
    }
    .footer .footer-contacts-h{
        top: 0;
    }
    .spacer154{
        display: block;
        width: 154px;
    }
    .spacer113{
        display: block;
        width: 113px;
    }
    .footer .footer-links{
        position: relative;
        top: 0;
        left: 0;
        width: 153px;
        height: 181px;
        font-family: "helvetica";
        font-size: 14px;
        font-weight: 400;
        line-height: 34px;
        font-style: normal;
        text-align: left;
        color: #709be7;
    }
    .footer .footer-links li{
        cursor: pointer;
    }
    .footer .footer-contact-info{
        position: relative;
        top: 0;
        left: 0;
        width: 135px;
        height: 136px;
        font-family: "roboto";
        font-size: 14px;
        font-weight: 400;
        line-height: 34px;
        font-style: normal;
        text-align: left;
        color: #709be7;
    }
    .footer .footer-socialIcons{   
        position: relative;
        top: 0;
        left: 0;
        width: 200px;
        display: flex;
        justify-content: space-between;
    }
    .footer .footer-socialIcons .footer-socialIcon{   
        width: 28.5px;
        height: 28.5px;
        background: #ffffff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}