.createPost{
    width: 366.8px;
    height: 79.3px;
    margin: auto;
    border-color: #e2e2ea;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
}
.createPost-inner{    
    margin: 13px;
}
.createPost-inner p{
    width: 30px;
    height: 11px;
    margin-top: 13px;
    margin-bottom: 13px;
    font-family: "helvetica";
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    font-style: normal;
    text-align: left;
    color: #595f6f;
}
.post-input-wrap{
    display: flex;
    align-items: center;
    padding: 13px 0;
}
.createPost .blue-dot{
    width: 14.9px;
    height: 14.9px;
    background: #1a47b0;
    border-radius: 100%;
}
.post-input-wrap input{
    width: 100%;
    height: 13px;
    font-family: "helvetica";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    font-style: normal;
    text-align: left;
    color: #696974;
    margin-left: 10px;   
    border: none;
    outline: none; 
    width: 100%;    
}
@media only screen and (min-width: 600px) {
    .only-mobile{
        display: none;
    }
    .createPost{
        width: 100%;   
        height: auto;     
        border-color: #e2e2ea;
        border-width: 2px;
        border-style: solid;
        padding-top: 29px;        
    }
    .createPost-inner{    
        margin: 20px;
    }
    .createPost-inner p{
        width: 54px;
        height: 19px;
        font-family: "helvetica";
        font-size: 26px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
        text-align: left;
        color: #595f6f;
    }
    .createPost .blue-dot{
        width: 70px;
        height: 69px;
        background: #1a47b0;
        border-radius: 100%;
    }
    .post-input-wrap input{
        width: calc(100% - 70px);
        height: 29px;
        font-family: "helvetica";
        font-size: 29px;
        font-weight: 400;
        line-height: 29px;
        font-style: normal;
        text-align: left;
        color: #696974;
    }
}